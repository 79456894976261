/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:9f2363fc-dd8f-411f-b56b-a107796ce079",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_vx5v8nU7y",
    "aws_user_pools_web_client_id": "4sogst4nmv974et6lvmspm2np2",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://0iwkej2gz0.execute-api.ca-central-1.amazonaws.com/jbhprod",
            "region": "ca-central-1"
        },
        {
            "name": "saveledgerentry",
            "endpoint": "https://emdlil9je3.execute-api.ca-central-1.amazonaws.com/jbhprod",
            "region": "ca-central-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ppetable-jbhprod",
            "region": "ca-central-1"
        },
        {
            "tableName": "locationinfo-jbhprod",
            "region": "ca-central-1"
        },
        {
            "tableName": "ppeledger-jbhprod",
            "region": "ca-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "qwantifyppecartprofile00827-jbhprod",
    "aws_user_files_s3_bucket_region": "ca-central-1",
    "aws_content_delivery_bucket": "qwppedev-jbhprod",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "https://d1g2yhcdyu4ly2.cloudfront.net"
};


export default awsmobile;
